html {
    font-family: 'Scada', sans-serif;
}

body {
    font-family: 'Scada', sans-serif;
    background: url(../../images/admin/menu-y-repeat.jpg) repeat-y;
}

a {
    color: #555;
}
a:hover {
    color: #588e36;
}

#wrapper {
    width: 100%;
}

#page-wrapper {
    padding: 0 15px;
    min-height: 568px;
    background-color: #fff;
}

.table>tbody>tr>td {
    padding-right: 15px;
}


@media(min-width:768px) {
    #page-wrapper {
        position: inherit;
        margin: 0 0 0 200px;
        padding: 0 30px;
        border-left: 1px solid #628a30;
    }
}

.navbar-top-links {
    margin-right: 0;
}

.navbar-top-links li {
    display: inline-block;
}

.navbar-top-links li:last-child {
    margin-right: 15px;
}

.navbar-top-links li a {
    padding: 15px;
    min-height: 50px;
    color: #fff;
}

.navbar-top-links .dropdown-menu li {
    display: block;
}

.navbar-top-links .dropdown-menu li:last-child {
    margin-right: 0;
}

.navbar-top-links .dropdown-menu li a {
    padding: 3px 20px;
    min-height: 0;
}

.navbar-top-links .dropdown-menu li a div {
    white-space: normal;
}

.navbar-top-links .dropdown-messages,
.navbar-top-links .dropdown-tasks,
.navbar-top-links .dropdown-alerts {
    width: 310px;
    min-width: 0;
}

.navbar-top-links .dropdown-messages {
    margin-left: 5px;
}

.navbar-top-links .dropdown-tasks {
    margin-left: -59px;
}

.navbar-top-links .dropdown-alerts {
    margin-left: -123px;
}

.navbar-top-links .dropdown-user {
    right: 0;
    left: auto;
}


.sidebar .sidebar-nav.navbar-collapse {
    padding-right: 0;
    padding-left: 0;
    
}

.sidebar .sidebar-search {
    padding: 15px;
}

.sidebar ul li {
    border-bottom: 1px solid #e7e7e7;
}

.sidebar ul li a.active {
    color: #fff;
    background: #85aa4d;
}

.sidebar .arrow {
    float: right;
}

.sidebar .fa.arrow:before {
    content: "\f104";
}

.sidebar .active>a>.fa.arrow:before {
    content: "\f107";
}

.sidebar .nav-second-level li,
.sidebar .nav-third-level li {
    border-bottom: 0!important;
}

.sidebar .nav-second-level li a {
    padding-left: 50px;
}

.sidebar .nav-third-level li a {
    padding-left: 52px;
}

@media(min-width:768px) {
    .sidebar {
        z-index: 1;
        position: absolute;
        width: 200px;
        margin-top: 51px;
        border-bottom: 0 !important;
    }

    .navbar-top-links .dropdown-messages,
    .navbar-top-links .dropdown-tasks,
    .navbar-top-links .dropdown-alerts {
        margin-left: auto;
    }
}

.btn-outline {
    color: inherit;
    background-color: transparent;
    transition: all .5s;
}

.btn-primary.btn-outline {
    color: #428bca;
}

.btn-success.btn-outline {
    color: #007336;
}

.btn-info.btn-outline {
    color: #5bc0de;
}

.btn-warning.btn-outline {
    color: #f0ad4e;
}

.btn-danger.btn-outline {
    color: #d9534f;
}

.btn-primary.btn-outline:hover,
.btn-success.btn-outline:hover,
.btn-info.btn-outline:hover,
.btn-warning.btn-outline:hover,
.btn-danger.btn-outline:hover {
    color: #fff;
}

.chat {
    margin: 0;
    padding: 0;
    list-style: none;
}

.chat li {
    margin-bottom: 10px;
    padding-bottom: 5px;
    border-bottom: 1px dotted #999;
}

.chat li.left .chat-body {
    margin-left: 60px;
}

.chat li.right .chat-body {
    margin-right: 60px;
}

.chat li .chat-body p {
    margin: 0;
}

.panel .slidedown .glyphicon,
.chat .glyphicon {
    margin-right: 5px;
}

.chat-panel .panel-body {
    height: 350px;
    overflow-y: scroll;
}

.login-panel {
    margin-top: 25%;
}

.flot-chart {
    display: block;
    height: 400px;
}

.flot-chart-content {
    width: 100%;
    height: 100%;
}

.dataTables_wrapper {
    position: relative;
    clear: both;
}

table.dataTable thead .sorting,
table.dataTable thead .sorting_asc,
table.dataTable thead .sorting_desc,
table.dataTable thead .sorting_asc_disabled,
table.dataTable thead .sorting_desc_disabled {
    background: 0 0;
}

table.dataTable thead .sorting_asc:after {
    content: "\f0de";
    float: right;
    font-family: fontawesome;
}

table.dataTable thead .sorting_desc:after {
    content: "\f0dd";
    float: right;
    font-family: fontawesome;
}

table.dataTable thead .sorting:after {
    content: "\f0dc";
    float: right;
    font-family: fontawesome;
    color: rgba(50,50,50,.5);
}

.btn-circle {
    width: 30px;
    height: 30px;
    padding: 6px 0;
    border-radius: 15px;
    text-align: center;
    font-size: 12px;
    line-height: 1.428571429;
}

.btn-circle.btn-lg {
    width: 50px;
    height: 50px;
    padding: 10px 16px;
    border-radius: 25px;
    font-size: 18px;
    line-height: 1.33;
}

.btn-circle.btn-xl {
    width: 70px;
    height: 70px;
    padding: 10px 16px;
    border-radius: 35px;
    font-size: 24px;
    line-height: 1.33;
}

.show-grid [class^=col-] {
    padding-top: 10px;
    padding-bottom: 10px;
    border: 1px solid #ddd;
    background-color: #eee!important;
}

.show-grid {
    margin: 15px 0;
}

.huge {
    font-size: 40px;
}

.panel-green {
    border-color: #5cb85c;
}

.panel-green .panel-heading {
    border-color: #5cb85c;
    color: #fff;
    background-color: #5cb85c;
}

.panel-green a {
    color: #007336;
}

.panel-green a:hover {
    color: #3d8b3d;
}

.panel-red {
    border-color: #d9534f;
}

.panel-red .panel-heading {
    border-color: #d9534f;
    color: #fff;
    background-color: #d9534f;
}

.panel-red a {
    color: #d9534f;
}

.panel-red a:hover {
    color: #b52b27;
}

.panel-yellow {
    border-color: #f0ad4e;
}

.panel-yellow .panel-heading {
    border-color: #f0ad4e;
    color: #fff;
    background-color: #f0ad4e;
}

/*.panel-yellow a {
    color: #fff;
}

.panel-yellow a:hover {
    color: #fff;
}*/


/*
spinner
*/

.loader {
  position: fixed;
  width: 100%;
  height: 100%;
  background: #fff;
  z-index: 9999;
  text-align: center;
  -webkit-transition: all .5s ease;
  -moz-transition: all .5s ease;
  transition: all .5s ease;
  opacity: 1;
}

.spinner {
  width: 40px;
  height: 40px;
  position: relative;
  top: 45%;
  left: 48%;

  background-color: #333;

  border-radius: 100%;  
  -webkit-animation: sk-scaleout 1.0s infinite ease-in-out;
  animation: sk-scaleout 1.0s infinite ease-in-out;
}

@-webkit-keyframes sk-scaleout {
  0% { -webkit-transform: scale(0) }
  100% {
    -webkit-transform: scale(1.0);
    opacity: 0;
  }
}

@keyframes sk-scaleout {
  0% { 
    -webkit-transform: scale(0);
    transform: scale(0);
  } 100% {
    -webkit-transform: scale(1.0);
    transform: scale(1.0);
    opacity: 0;
  }
}

/*
spinner end
*/


.page-header {
    margin: 30px 0 20px;
    border-bottom: 0;
    color: #555555;
    text-align: center;
    font-weight: bold;
}

.pagination {
    margin: 0 0 10px 0;
}
.panel-body-solo {
    padding: 0 0 25px 0;
}

.file-inline {
    position: relative;
    display: inline-block;
    padding-left: 5px;
    margin-bottom: 0;
    vertical-align: middle;
}

.alert-danger li {
    margin-left: 66px;
}

.barometar-green {
    font-size: 20px !important; 
    color: green;
}

.barometar-red {
    font-size: 20px !important; 
    color: maroon;
}

.barometar-p {
    padding-bottom: 10px;
    margin-bottom: 10px;
    border-bottom: 1px solid #eee;
}

.edit-news-img {
    height: 150px;
}

.panel {
    border-radius: 0px;

}
.panel-heading {
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
}
.btn {
    border-radius: 0px;
}

a {
    transition: 0.5s
}
a:hover {
    transition: 0.5s
}

.alert {
    border-radius: 0;
}

.list-group-item:first-child {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.list-group-item:last-child {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
}
.navbar-default {
    color: #fff;
    background: url(../../images/admin/site-generator-bg.png) repeat-x;
    border-top: 5px solid #344a25;
    border-bottom: 1px solid #344a25;
}
.sidebar {
    background: url(../../images/admin/menu-y-repeat.jpg) repeat-y;
    color: #fff;
    border-top: 0;
}

.sidebar ul li {
    border-bottom: 1px solid #628a30;
    color: #b4bcc8;
}

.sidebar ul li.active {
    /*background: #2C3542;*/
}
.sidebar ul li a {
    color: #fff;
}
.nav>li>a:hover {
    background: #2C3542;
}
.sidebar .arrow {
    color: #fff;
}

.navbar-default .navbar-brand {
    color: #85aa4d;
}

.nav .open>a, .nav .open>a:focus, .nav .open>a:hover {
    background-color: #85aa4d;
    color:#fff;
}

.nav>li>a:focus, .nav>li>a:hover {
    color: #fff;
    background: #85aa4d;
}

.nav-second-level li {
    background: url(../../images/admin/menu-y-repeat.jpg) repeat-y;
    background-color: #85aa4d;
}
.dropdown-toggle a {
    color: #fff;
}

.dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1000;
    display: none;
    float: left;
    min-width: 160px;
    padding: 5px 0;
    /* margin: 2px 0 0; */
    font-size: 14px;
    text-align: left;
    list-style: none;
    background-color: #85aa4d;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
    border: 1px solid #ccc;
    border: 1px solid rgba(0,0,0,.15);
    border-radius: 0px;
    -webkit-box-shadow: 0;
    box-shadow: 0;
    margin: 0;
    color: #fff;
    -webkit-box-shadow: 0 0px 0px rgba(0,0,0,.175);
    box-shadow: 0 0px 0px rgba(0,0,0,.175);
}

.form-control, select {
    border-radius: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
    border: 1px solid #e3e3e3;
    background: #fcfcfc;
    height: 35px;
    line-height: 1;
    padding: 5px 10px;
    color: #555555;
    resize: none;
    -webkit-transition: all 200ms ease-in;
    -o-transition: all 200ms ease-in;
    -moz-transition: all 200ms ease-in;
}


.form-control:focus, select:focus {
    border: 1px solid #c3ccda;
    -webkit-box-shadow: none;
    box-shadow: none;
}

input:-webkit-autofill, textarea:-webkit-autofill, select:-webkit-autofill {
    -webkit-box-shadow: 0 0 0px 1000px #fcfcfc inset;
    background-color: rgb(255, 255, 255) !important;
    background-image: none;
    color: rgb(0, 0, 0);
}
input:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0px 1000px #fcfcfc inset;
    background-color: rgb(255, 255, 255) !important;
}

.has-error .form-control {
    border-color: #a94442;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.form-title {
    color: #555;
    text-align: center;
    font-size: 30px;
    font-weight: bold;
    margin-bottom: 25px;
}

.login {
    background-color: #fff;
}

.login-logo {
    margin: 0 auto;
    margin-top: 60px;
    padding: 15px;
    text-align: center;
}
.panel {border: 0;}
.panel-default>.panel-heading {
    border-bottom: 0;
    border: 1px solid #4a7c2d;
}


.panel-body {
    border-left: 1px solid #c5c5c5;
    border-right: 1px solid #c5c5c5;
    border-bottom: 2px solid #c5c5c5;
}

.panel-heading {
    background: #6c9e4a;
    background: -moz-linear-gradient(top, #6c9e4a 0%, #578d35 100%);
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #6c9e4a), color-stop(100%, #578d35));
    background: -webkit-linear-gradient(top, #6c9e4a 0%, #578d35 100%);
    background: -o-linear-gradient(top, #6c9e4a 0%, #578d35 100%);
    background: -ms-linear-gradient(top, #6c9e4a 0%, #578d35 100%);
    background: linear-gradient(top, #6c9e4a 0%, #578d35 100%);
    -ms-filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#6c9e4a', endColorstr='#578d35', GradientType=0 );
    border-bottom: 1px solid #4a7c2d;
}

.panel-default>.panel-heading {
    color: #fff;
    text-transform: uppercase;
}

.panel-default.blue-g {
    border: 1px solid #869ab3;
}

.panel-heading.blue-g {
    background-color: #869ab3;
    color: #b4bcc8;
}

.dropdown-menu>li>a {
    color: #b4bcc8;
}

.dropdown-menu>li>a:hover {
    color: #fff;
    background-color: #3d4957;
}

.main-p {
    padding: 15px 0 0 15px;
}
.dropdown-menu>li>a { color: #fff; }
.dropdown-menu>li>a:hover {
    color: #fff;
    background-color: #85aa4d;
}

.btn-submit {
    background: #84b66b url(../../images/admin/button.png) repeat-x;
    height: 29px;
    line-height: 29px;
    color: #fff;
    font-size: 14px;
    padding: 0px 15px;
    margin: 0;
    display: inline-block;
    -webkit-transition: all 200ms ease-in;
    -o-transition: all 200ms ease-in;
    -moz-transition: all 200ms ease-in;
    cursor: pointer;
    /*margin-bottom: 20px;*/
    -webkit-border-radius: 3px;
    border-radius: 3px;
    -webkit-box-shadow: 0px 0px 0px 1px #64914d;
    box-shadow: 0px 0px 0px 1px #64914d;
    width: auto;
    border: none;
    width: 100%;
    text-align: center;
}

.btn-submit.small {
    width: 250px;
}

.btn-submit.tiny {
    width: 50px;
}

.btn-submit.micro {
    width: 30px;
    padding: 0 6px;
    margin-left: 4px;
    margin-bottom: 0;
}

.btn-submit:hover {
    color: #fff;
    text-decoration: none;
    opacity: 0.85;
}

.btn-submit:active {
    color: #fff;
    text-decoration: none;
}

.btn-submit:visited {
    color: #fff;
    text-decoration: none;
}

.btn-submit.red {
    background: #da2424 url(../../images/admin/button.png) repeat-x;
    -webkit-box-shadow: 0px 0px 0px 1px #bd2d23;
    box-shadow: 0px 0px 0px 1px #bd2d23;
}

.btn-submit.orange {
    background: #de6012 url(../../images/admin/button.png) repeat-x;
    -webkit-box-shadow: 0px 0px 0px 1px #bd8523;
    box-shadow: 0px 0px 0px 1px #bd8523;
}

a.list-group-item {
    transition: none;
}
a.list-group-item:hover {
    color: #fff;
    text-decoration: none;
    background: #6c9e4a;
    background: -moz-linear-gradient(top, #6c9e4a 0%, #578d35 100%);
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #6c9e4a), color-stop(100%, #578d35));
    background: -webkit-linear-gradient(top, #6c9e4a 0%, #578d35 100%);
    background: -o-linear-gradient(top, #6c9e4a 0%, #578d35 100%);
    background: -ms-linear-gradient(top, #6c9e4a 0%, #578d35 100%);
    background: linear-gradient(top, #6c9e4a 0%, #578d35 100%);
    -ms-filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#6c9e4a', endColorstr='#578d35', GradientType=0 );
    transition: none;
}

.panel-heading.panel-fruit {
    background: #ce5d26;
    background: -moz-linear-gradient(top, #ce5d26 0%, #c54716 100%);
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #ce5d26), color-stop(100%, #c54716));
    background: -webkit-linear-gradient(top, #ce5d26 0%, #c54716 100%);
    background: -o-linear-gradient(top, #ce5d26 0%, #c54716 100%);
    background: -ms-linear-gradient(top, #ce5d26 0%, #c54716 100%);
    background: linear-gradient(top, #ce5d26 0%, #c54716 100%);
    -ms-filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ce5d26', endColorstr='#c54716', GradientType=0 );
    border: 1px solid #b3320f;
    border-bottom: 1px solid #b3320f;
}

.panel-heading.panel-misc {
    background: #906030;
    background: -moz-linear-gradient(top, #906030 0%, #7e4b1e 100%);
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #906030), color-stop(100%, #7e4b1e));
    background: -webkit-linear-gradient(top, #906030 0%, #7e4b1e 100%);
    background: -o-linear-gradient(top, #906030 0%, #7e4b1e 100%);
    background: -ms-linear-gradient(top, #906030 0%, #7e4b1e 100%);
    background: linear-gradient(top, #906030 0%, #7e4b1e 100%);
    -ms-filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#906030', endColorstr='#7e4b1e', GradientType=0 );
    border: 1px solid #653c18;
    border-bottom: 1px solid #653c18;
}

.panel-heading.panel-danger {
    background: #906030;
    background: -moz-linear-gradient(top, #df5959 0%, #be2020 100%);
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #df5959), color-stop(100%, #be2020));
    background: -webkit-linear-gradient(top, #df5959 0%, #be2020 100%);
    background: -o-linear-gradient(top, #df5959 0%, #be2020 100%);
    background: -ms-linear-gradient(top, #df5959 0%, #be2020 100%);
    background: linear-gradient(top, #df5959 0%, #be2020 100%);
    -ms-filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#df5959', endColorstr='#be2020', GradientType=0 );
    border: 1px solid #bd2d23;
    border-bottom: 1px solid #bd2d23;
}

.no-border {
    border-left: 0;
    border-right: 0;
    border-bottom: 0;
}
.pagination>li>a {
    color: #344a25;
}

.pagination>.active>a, .pagination>.active>a:focus, .pagination>.active>a:hover, .pagination>.active>span, .pagination>.active>span:focus, .pagination>.active>span:hover {
    background-color: #85aa4d;
    border-color: #85aa4d;
}

.pagination>li>a:hover {
    color: #344a25;
}
